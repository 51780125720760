exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-archive-jsx": () => import("./../../../src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-commercial-jsx": () => import("./../../../src/pages/commercial.jsx" /* webpackChunkName: "component---src-pages-commercial-jsx" */),
  "component---src-pages-documentary-jsx": () => import("./../../../src/pages/documentary.jsx" /* webpackChunkName: "component---src-pages-documentary-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-video-jsx": () => import("./../../../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */)
}

